import Logo from "./image/trugreenlogo.svg"

const PageHeader = () => {
  return (
    <>
        <div className='h-[40px] md:h-[60px] px-[16px] py-[8px] md:py-0 md:px-[40px] lg:px-[80px] flex justify-between'>
            <img src={Logo} height={20} width={130} alt='Logo'></img>
        </div>
    </>
  )
}

export default PageHeader