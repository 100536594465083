import {Text, Rect, View, Svg, Circle, StyleSheet  } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    textRegular: {
        position: "absolute",
        color: 'white',
        fontFamily: 'SSP_Regular',
        letterSpacing: '0.36px',
        fontSize: '12px',
        marginLeft: '40px'
    },
    soilTextSemiBold: {
        position: "absolute",
        color: 'black',
        fontFamily: 'SSP_SemiBold',
        letterSpacing: '0.36px',
        fontSize: '12px',
        marginLeft: '40px'
    },
    textRegularBlack: {
        position: "absolute",
        color: 'black',
        fontFamily: 'SSP_Regular',
        letterSpacing: '0.36px',
        fontSize: '12px',
    }
  });

const FBRLpdf = (props) => {
    const {label, temp, moisture, greenIndexScore, divMargin} = props
    let fbrlDim = 80, marginLeft = 28, marginTop = 43, recX = -30
  
   return (
     <View style={{marginTop: `${divMargin}px`}}>
        <Svg width={fbrlDim} height={fbrlDim} viewBox={`0 0 ${fbrlDim} ${fbrlDim}`} style={{marginLeft: '20px'}}>
            <Circle cx={`${fbrlDim/5}`} cy={`${fbrlDim/1.5}`} r={15} fill="#01a136"/>
        </Svg>
        <Svg width={330} height={50} viewBox='0 0 155 50' style={{marginLeft: '5px', marginTop: `-${marginTop+8}px`}}>
            <Rect x={recX} rx={5} ry={5} y="10" width="80" height="25" stroke="black"/>
            <Rect x={recX+95} rx={5} ry={5} y="10" width="80" height="25" stroke="black"/>
            <Rect x={recX+190} rx={5} ry={5} y="10" width="80" height="25" stroke="black"/>
        </Svg>
        <Text style={{ ...styles.textRegular, fontSize: '16px', marginLeft: `${marginLeft}px`, top: `${marginTop}px`}} > {label} </Text>
        <Text style={{ ...styles.textRegularBlack, fontSize: '14px', marginLeft: `${marginLeft+75+(temp === ""?0:(-3*((temp.length))))}px`, top: `${marginTop}px`}} >{temp === ""?"-":temp} </Text>
        <Text style={{ ...styles.textRegularBlack, fontSize: '14px', marginLeft: `${marginLeft+168+(moisture === ""?0:(-3*(moisture.length)))}px`, top: `${marginTop}px`}} >{moisture === ""?"-":moisture} </Text>
        <Text style={{ ...styles.textRegularBlack, fontSize: '14px', marginLeft: `${marginLeft+256+(greenIndexScore.toString().length === 1?6:(0.3*(greenIndexScore.toString().length)))}px`, top: `${marginTop}px`}} >{greenIndexScore === ""?"NA":greenIndexScore} </Text>
        
     </View>
  )
}

export default FBRLpdf