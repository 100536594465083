import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ImageModal from "./ImageModal";
import PageHeader from "../PageHeader";
import "./HLA.css";

const HLA = () => {

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [hlaQueryParams, setHLAQueryParams] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [noData, setNoData] = useState("");
  const [onlyImage, setOnlyImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingTime, setLoadingTime] = useState(5000);
  const workOrderNum = searchParams.get("WorkOrderNum");
  const UniquePhotoId = searchParams.get("UniquePhotoId");
  const ImageOnly = searchParams.get("ImageOnly");

  const tableFields = [
    "workOrderNum",
    "technicianID",
    "customerNumber",
    "serviceLine",
    "photoCaption",
    "locationDescription",
  ];

  const getHLAData = async () => {
    const getDataURL = `${process.env.REACT_APP_HLA_GETDATA_URL}${hlaQueryParams}`;
  
    try {
      const startTime = Date.now();  
      const res = await fetch(getDataURL);  
      const endTime = Date.now();  
      const elapsedTime = endTime - startTime;  
      if (res.status === 200) {
        const result = await res.json();  
        if (result?.technicianDetails) setTableData(result.technicianDetails);
        else if (ImageOnly) setOnlyImage(result);
        else setNoData(result);  
        setLoadingTime(Math.max(0 - elapsedTime));
      }
    } catch (e) {
      console.log("Error in Getting Data: " + e);
    }
  };  

  useEffect(() => {
    if (hlaQueryParams) getHLAData();
    else {
      switch (true) {
        case workOrderNum !== null:
          setHLAQueryParams(`/WorkOrderNumber?WorkOrderNum=${workOrderNum}`);
          break;
        case UniquePhotoId !== null && ImageOnly !== null:
          setHLAQueryParams(`/ImageURL?UniquePhotoID=${UniquePhotoId}`);
          break;
        case UniquePhotoId !== null:
          setHLAQueryParams(`/UniquePhotoID?UniquePhotoID=${UniquePhotoId}`);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [hlaQueryParams]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, loadingTime);

    return () => clearTimeout(delay);
  }, [loadingTime]);

  const isUniqueImagePage =
    UniquePhotoId !== null &&
    ImageOnly !== null &&
    ImageOnly === "1" &&
    onlyImage !== "";

  return (
    <div className={`border border-[#046A38] m-4 teww orflw ${isLoading ? "blur-background" : ""}`}>
      {isLoading && (
        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-10">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" style={{ color: "#1d7e16" }} />
          <p className="text-lg ml-2">Loading...</p>
        </div>
      )}

      <div className="ptn">
        {!isUniqueImagePage && tableData.length > 0 && (
          <PageHeader />
        )}

        {tableData.length > 0 && (
          <h1 style={{ marginLeft: "20px" }} id="componentToHide">
            {tableData.length === 1
              ? "Search Result: 1 row"
              : `Search Result: ${tableData.length} rows`}
          </h1>
        )}
      </div>

      <div className="h-full justify-center items-center">
        <div className="max-w-[100%] overflow-x-auto overflow-y-auto ">
          {UniquePhotoId &&
            ImageOnly &&
            ImageOnly === "1" &&
            onlyImage !== "" && (
              <span>
                {onlyImage !== "No Record Found." ? (
                  <div
                    className="flex items-center justify-center border-t py-4 border-[#046A38] "
                    style={{ backgroundColor: "black" }}
                  >
                    <img src={onlyImage} width={800} alt="workOrderPhoto" />
                  </div>
                ) : (
                  <div className="flex items-center justify-center border-t py-4 border-[#046A38] h-[400px] font-sspBold text-[20px]">
                    Image Not Found
                  </div>
                )}
              </span>
            )}

          {(UniquePhotoId || workOrderNum) && !ImageOnly && (
            <div className="table-container">
              <table
                className="table-fixed w-full border-collapse"
                style={{ overflow: "auto" }}
              >              

                <thead
                  className="border-b font-medium dark:border-neutral-500 ptn"
                  style={{ position: "sticky" }}
                >
                  <tr className="bg-gradient-to-r from-[#046A38] to-[#00B140] text-white h-[3rem] text-[18px] font-sspBold">
                    <th scope="col" class="px-6 py-4 psn">
                      WorkOrder
                      <br />
                      Number
                    </th>

                    <th scope="col" class="px-6 py-4 psn">
                      Technician ID
                    </th>

                    <th scope="col" class="px-6 py-4 psn">
                      Customer
                      <br />
                      Number
                    </th>

                    <th scope="col" class="px-6 py-4 psn">
                      Service
                      <br />
                      Line
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-4 psn photo-caption-header"
                    >
                      Photo
                      <br />
                      Caption
                    </th>

                    <th scope="col" class="px-6 py-4 psn">
                      Location
                      <br />
                      Description
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-4 psn"
                      className="w-[100px]"
                    ></th>
                  </tr>
                </thead>

                {isLoading ? (
                  <tbody>
                    <tr>
                      <td
                        colSpan={tableFields.length + 1}
                        className="text-center p-4"
                      >                       
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {
                      
                      tableData.length !== 0 &&
                        // eslint-disable-next-line
                        tableData.map((tableRow, i) => {
                          if (
                            workOrderNum &&
                            workOrderNum === tableRow.workOrderNum
                          ) {
                            return (
                              <tr
                                key={i}
                                className="h-[4rem] "
                                class="border-b dark:border-neutral-500"
                              >
                                {tableFields.map((tableCell, j) => {
                                  return (
                                    <td
                                      class="whitespace-nowrap px-6 py-4 font-medium"
                                      className={`${
                                        j === 4 ? `text-justify` : `text-center`
                                      } px-5 py-3 `}
                                      key={i + "-" + j}
                                    >
                                      {tableRow[tableCell]}{" "}
                                    </td>
                                  );
                                })}

                                <td className="text-center">
                                  <ImageModal
                                    uniquePhotoID={tableRow.uniquePhotoID}
                                  />
                                </td>
                              </tr>
                            );
                          } else if (
                            UniquePhotoId &&
                            !ImageOnly &&
                            UniquePhotoId === tableRow.uniquePhotoID
                          ) {
                            return (
                              <tr key={i} className=" h-[4rem] ">
                                {tableFields.map((tableCell, j) => {
                                  return (
                                    <td
                                      class="whitespace-nowrap px-6 py-4 font-medium"
                                      className={`${
                                        j === 4 ? `text-justify` : `text-center`
                                      } px-5 py-3`}
                                      key={i + "-" + j}
                                    >
                                      {tableRow[tableCell]}{" "}
                                    </td>
                                  );
                                })}

                                <td
                                  class="whitespace-nowrap px-6 py-4 font-medium"
                                  className="text-center"
                                >
                                  <ImageModal
                                    uniquePhotoID={tableRow.uniquePhotoID}
                                  />
                                </td>
                              </tr>
                            );
                          }
                        })
                    }
                    {/* just for test */}
                    {noData !== "" && (
                      <tr class="border-b dark:border-neutral-500">
                        <td
                          className="text-center px-5 py-3 font-sspBold text-[20px]"
                          colSpan={tableFields.length + 1}
                        >
                          {/* {noData} */}
                          WorkOrder# Not Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HLA;
