import { useEffect, useState } from "react"
import plusRight from "./image/plusRight.svg"
import minusLeft from "./image/minusLeft.svg"
import minusGreenLeft from "./image/minusGreenLeft.svg"
import PlusIcon from "./image/PlusIconSVG.svg"
import MinusIcon from "./image/MinusIconSVG.svg"
import DisabledMinusIcon from "./image/DisabledMinusIconSVG.svg"

const Counter = (props) => {
    const {handleTurfColorRating, calc_turfColorRating, register, eleName, eleVal, method: setEleVal, errors} = props;

    const [disableMinus, setDisableMinus] = useState(true)
    
    const updateVal = (operator) => {
        let elVal = (eleVal !== "")?parseFloat(eleVal): eleVal
        if(eleVal < 0){setEleVal(""); elVal = "";}
        const eName = document.getElementsByName(eleName)[0];
        eName.focus()

        switch(operator){
            case "-" : 
            if(elVal !== ""){
            if(elVal !== 0){
                if(elVal >= 1){
                     setEleVal(parseFloat((elVal - 1).toFixed(1))); 
                     if(elVal === 1)  { setDisableMinus(true); setEleVal("")}
                }
                else if(elVal > 0 && elVal < 1){ setEleVal("");   setDisableMinus(true) }
            }}
            break;
            case "+" : 
                if(elVal === "") setEleVal(1);
                else if(elVal >= 0) setEleVal(parseFloat((elVal + 1).toFixed(1)));
                setDisableMinus(false)
            break;
            default: break;
        }

        if(eleVal <= 10)
        removeError()
    }

    const removeError = () => {
        delete errors['tcF'];   delete errors['tcB'];   delete errors['tcR'];   delete errors['tcL'];
    }
    
    const handleCounterFocus = (e) => {
        if(e.target.value === "0" || e.target.value === 0){
            e.target.value = "";
            setEleVal("")
        }
    }

    const handleCounterBlur = (e) => {
        if(e.target.value === "0" || e.target.value === 0){
            e.target.value = "";
            setEleVal("")
        }
    }

    const handleUpdate = (e) => {
        handleTurfColorRating(e); 
        if(e.target.value > 0) setDisableMinus(false);
        else setDisableMinus(true);

        if(e.target.value <= 10)
        removeError()
    }

    useEffect(() => {
        calc_turfColorRating()
        if(eleVal >= 0.1 && eleVal <= 10)
            delete errors[`${eleName}`]
            //eslint-disable-next-line
    },[eleVal, calc_turfColorRating]);

  return (
    
    <div className="flex w-[128px] h-[36px] justify-start ">

        <div onClick={() => updateVal("-")} className="">
            {disableMinus && <div className="flex items-center justify-center w-[40px] h-[36px]">
                <img src={minusLeft} alt=""/>
                <img src={DisabledMinusIcon} alt="" className="w-[18px] -ml-[28px] mr-[10px]"/>
            </div>}

            {!disableMinus && <div className="flex items-center justify-center w-[40px] h-[36px]">
                <img src={minusGreenLeft} alt="" />
                <img src={MinusIcon} alt="" className="w-[22px] -ml-[31px] mr-[10px]"/>
            </div>}
        </div>

        <input type="number" step="any" inputMode="decimal" name={eleName} className='border border-x-1 -ml-[.5px] border-[#B7B7B7] text-[#303236] text-[18px] w-[48px] h-[36px] text-center' placeholder="NA" {...register(eleName)} value={eleVal<0 ?"":eleVal} onChange={handleUpdate} onFocusCapture={handleCounterFocus} onBlurCapture={handleCounterBlur} id={eleName}/>
        
        <div className="flex items-center justify-center w-[40px] h-[36px]" onClick={() => {updateVal("+"); }}>
            <img src={plusRight} alt="" />
            <img src={PlusIcon} alt="" className="h-[18px] w-[18px] -ml-[28px] mr-[10px]" />
        </div>
    </div>
    
  )
}

export default Counter;