// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1300px) {
    .mobile-margin-right {
      margin-right: 10px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/HLA/ImageModal.css"],"names":[],"mappings":"AAAA;IACI;MACE,kBAAkB;IACpB;EACF","sourcesContent":["@media (max-width: 1300px) {\n    .mobile-margin-right {\n      margin-right: 10px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
