import {Route, Routes, useLocation} from "react-router-dom"
import Result from './Result';
import Form from './Form';
import { AnimatePresence } from "framer-motion";

const Motion = () => {
    const location = useLocation();
    //console.log(location.state);

    return (
      <AnimatePresence mode="wait" initial={false} >
        <Routes key={location.pathname} location={location}>
          <Route exact path='/' element={<Form/>}></Route>
          <Route exact path='/result' element={<Result data={location.state?.formData}/>}></Route>
        </Routes>
      </AnimatePresence>
    );
}

export default Motion