import { useEffect } from "react";
import greenCircle from "./image/greenCircle.svg"

const FBRL = (props) => {
    const {type, register, values, method, errors} = props;
    const [front, back, right, left] = values;

    const handleFBRL = (e, index) => {
        if(e.target.name.substr(0, e.target.name.length-1) === "moisture"){
            if(e.target.value >= 0 && e.target.value <= 100)
            delete errors[`${e.target.name}`]
        }
        else if(e.target.name.substr(0, e.target.name.length-1) === "temp"){
            if(e.target.value >= -50 && e.target.value <= 150)
            delete errors[`${e.target.name}`]
        }
        let newVals = [...values]
        // newVals[index] = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value) ;
        newVals[index] = e.target.value;
        method(newVals)
    }
    
    useEffect(() => {
        //console.log(values);
    }, [values])
    
  return (
    <div className='flex flex-col -mt-[3px]'>
        <div className='flex mt-[12px]'>
            <div className='flex items-center'>
                <div className="w-[25px] "><img src={greenCircle} alt="" /></div>
                <div className="text-[18px] text-white font-sspReg absolute px-[8px]" >F</div>
            </div>
            <input type="number" step="any" inputMode={`${type === "moisture"?"decimal":""}`} name={`${type}F`} {...register(`${type}F`)} value={front} onChange={(e) => handleFBRL(e, 0)} className="h-[36px] w-[90px] lg:w-[114px] border border-[#A0A0A0] rounded-md text-center ml-[6px] text-[20px]" placeholder="-" />
        </div>
        <div className='flex mt-[12px]'>
            <div className='flex items-center'>
                <div className="w-[25px] "><img src={greenCircle} alt="" /></div>
                <div className="text-[18px] text-white font-sspReg absolute px-[8px]">B</div>
            </div>
            <input type="number" step="any" inputMode={`${type === "moisture"?"decimal":""}`} name={`${type}B`} {...register(`${type}B`)} value={back} onChange={(e) => handleFBRL(e, 1)} className="h-[36px] w-[90px] lg:w-[114px] border border-[#A0A0A0] rounded-md text-center ml-[6px] text-[20px]" placeholder="-" />
        </div>
        <div className='flex mt-[12px]'>
            <div className='flex items-center'>
                <div className="w-[25px] "><img src={greenCircle} alt="" /></div>
                <div className="text-[18px] text-white font-sspReg absolute px-[8px]">R</div>
            </div>
            <input type="number" step="any" inputMode={`${type === "moisture"?"decimal":""}`} name={`${type}R`} {...register(`${type}R`)} value={right} onChange={(e) => handleFBRL(e, 2)} className="h-[36px] w-[90px] lg:w-[114px] border border-[#A0A0A0] rounded-md text-center ml-[6px] text-[20px]" placeholder="-" />
        </div>
        <div className='flex mt-[12px]'>
            <div className='flex items-center'>
                <div className="w-[25px] "><img src={greenCircle} alt="" /></div>
                <div className="text-[18px] text-white font-sspReg absolute px-[8px]">L</div>
            </div>
            <input type="number" step="any" inputMode={`${type === "moisture"?"decimal":""}`} name={`${type}L`} {...register(`${type}L`)} value={left} onChange={(e) => handleFBRL(e, 3)} className="h-[36px] w-[90px] lg:w-[114px] border border-[#A0A0A0] rounded-md text-center ml-[6px] text-[20px]" placeholder="-" />
        </div>
    </div>
  )
}

export default FBRL