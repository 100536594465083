import { useState, useEffect, Fragment } from "react";
import './ImageModal.css'

const ImageModal = ({ uniquePhotoID }) => {
  const [imageURL, setImageURL] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openCloseModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const getImageUrl = async (uniquePhotoID) => {
    const getDataURL = `${process.env.REACT_APP_HLA_GETDATA_URL}/ImageURL?UniquePhotoID=${uniquePhotoID}`;
    const res = await fetch(getDataURL);
    const result = await res.json();
    setImageURL(result);
  };

  useEffect(() => {
    getImageUrl(uniquePhotoID);
  }, [uniquePhotoID]);

  const dLink = `${window.location.origin}/hla/search?UniquePhotoId=${uniquePhotoID}&ImageOnly=1`;

  return (
    <Fragment>
      <a
        target="_blank"
        rel="noreferrer"
        className="bg-[#046A38] rounded text-white py-[6px] px-[20px] mobile-margin-right"
        href={dLink}
      >
        View
      </a>

      {modalIsOpen && (
        <Fragment>
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="close-button" onClick={openCloseModal}>
                Close
              </button>
              <div className="modal-image">
                {imageURL !== "No Record Found." ? (
                  <img src={imageURL} alt="workOrderPhoto" width={700} />
                ) : (
                  <div className="modal-message">Image Not Found</div>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ImageModal;
