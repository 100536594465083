import { useEffect, useState } from "react";


const AddressAutoComplete = (props) => {
    const {address, setAddressVal, setOpenDropDown, openDropDown, errors, clearErrors} = props
    const take = 10;
    
    const [addresses, SetAddresses] = useState([]);

    useEffect(() => {
        const fetchAddress = async(address) =>{
          if(address.length > 3){
            const requestBody = `?query=${address}&country=${process.env.REACT_APP_COUNTRY_CODE}&take=${take}&auth-token=${process.env.REACT_APP_EXPERIAN_AUTH_TOKEN}`;
            
            try{
                const res = await fetch(`${process.env.REACT_APP_EXPERIAN_ADDRESS_URL}${requestBody}`)
                if(res.status !== 200){
                    // errors.cAddress = { message: "Error: Can't get Address" }
                    console.log("Error: "+ res.status +" " +res.statusText);
                    return 
                }
                const output = await res.json();
                //console.log(output);
        
                const items = [];
                if(output.totalMatches){
                    for(let i in output.results){
                        items.push(output.results[i].suggestion)
                    }
                    SetAddresses(items);
                    document.getElementsByName('cAddress')[0].focus()
                }
            }catch(e){
                console.log("Error while fetching address: ", e);
            }
          }
        }
        if(openDropDown)
            fetchAddress(address)
        else clearErrors()
    
    }, [address, openDropDown, clearErrors])

    return (
        <>
        {<div className={`absolute bg-white w-[358px] md:w-[328px] lg:w-[400px] max-h-[200px] border border-[#046A38] py-[0px] mt-[2px] font-sspReg text-[14px] overflow-auto ${(addresses.length?'':'hidden')} z-10`}>
            {openDropDown && address.length && addresses.length && addresses.map((addressVal, i) => {
                return <div key={`address${i}`} onClick={() => {setOpenDropDown(false); setAddressVal(addressVal); document.getElementsByName('cAddress')[0].focus(); delete errors.cAddress}} className="leading-[30px] border-b-[#046A38] border-t-0 hover:bg-[#F1F1F1] hover:border px-[10px]">{addressVal}</div>
            })}
        </div>
        }
        </>
    )
}

export default AddressAutoComplete