import React, { useState } from 'react'

const Tile = (props) => {
    const {type, method: setDensity, errors} = props

    const [tile, setTile] = useState(["", false])

    const handleTile = (tileIndex) => {
        const eName = document.getElementsByName(type+"Density")[0];
        eName.focus()

        delete errors[`${type}Density`]
        
        if(tileIndex+"" === tile[0]){
            setTile(["", false]);
            setDensity("")
        } else if (tileIndex+"" !== "") 
        {
            setTile([tileIndex+"", true]);
            setDensity(tileIndex+"")
        }
    }

  return (
    <div className='flex justify-between w-[660px] md:w-full'>
        {
            [1, 2, 3, 4, 5].map((i) => {
                return <div key={`${type}_${i}`} className={`h-[120px] w-[100px]`} ><img src={require(`./image/${type}Tile${i}@2x.png`)} alt={`${type}Tile${i}`} className={`border ${(tile[0] === i+"" && tile[1]?`border-[#01A136] border-[6px] p-[2px]` :`border-0`)} cursor-pointer rounded-md`} onClick={() => handleTile(i)} /><div className='flex justify-center font-sspReg text-[16px]'>{i}</div></div>
            })
        }
    </div>
  )
}

export default React.memo(Tile)