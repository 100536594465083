import './App.css';
import Motion from './Motion';
import Footer from './Footer';
import PageHeader from './PageHeader';
import HLA from './HLA/HLA';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from 'react';

const LawnScanApp = lazy(() => import('./components/Lawnscan'));
 
function App() {
  const isHLA = window.location.pathname.match(/^\/hla/);
 
  return (
    <>
      {isHLA && (
        <span>
          <Router>
            <Routes>
              <Route index path='/*' element={<span>404: No Page Found</span>} />
              <Route exact path='/hla/search' element={<HLA />} />
            </Routes>
          </Router>
        </span>
      )}
      {!isHLA && (
        <div className='mx-auto w-auto md:w-[768px] lg:w-[1024px] border border-gray-300'>
          <PageHeader />
          <Router>
              {/* <Motion /> */}
            <Routes>
              <Route
                path="/getLawnAssessment"
                element={
                  <Suspense fallback={<div></div>}>
                    <LawnScanApp />
                  </Suspense>
                }
              />
              <Route path="*" element={<Motion />} />
            </Routes>
          </Router>
          <Footer />
        </div>
      )}
    </>
  );
}
 
export default App;